<template>
  <div id="exhibition-3" class="exhibition-container">
    <btnHome />
    <div class="right-toolbar">
      <div class="subtitle">
        <img
          src="@/assets/images/subtitle-3.svg"
          width="100%"
          height="auto"
          alt=""
        />
      </div>
      <div class="section-btn-container">
        <div
          v-for="(item, i) in sectionsName"
          :key="`section-${i}`"
          :class="{ 'section-btn': true, active: currentSection == i }"
          @click="clickSection(i)"
        >
          <div class="btn-color"></div>
        </div>
      </div>
      <musicPlayer />
    </div>
    <section
      ref="sectionWrapper"
      :class="{
        'section-wrapper': true,
        'pointer-events-none': !dimmed,
        'fade-out': !dimmed,
      }"
      v-if="$route.params.id == 1"
    >
      <div class="scroll-section section-intro mb-15">
        <div class="section-title">
          <img
            src="@/assets/images/exhibition-3-title@2x.png"
            class="title"
            alt="閱讀空間 bibliotheca"
            width="100%"
            height="auto"
          />
        </div>
        <div class="section-panel-text">
          <p>
            創作團隊踏上探索中亞音樂之旅，邊走邊發現，邊發問邊思考。
          </p>
          <p>
            團隊首先遇上屬於大草原的傳統遊牧音樂文化，體驗哈薩克人在三大人生階段都不可或缺的音樂元素，聆聽吉爾吉斯最有代表性的樂器——火不思——感化惡人的故事。
          </p>
          <p>
            正當團隊在大草原上記錄著考察的各種發現、沉醉在遊牧文化與大自然的緊密連結時，突然時空大兜亂，瞬間轉移到中世紀中亞古城，與遊牧牧文明截然不同的文化氣息撲面而來……見證了當時著名的博學家法拉比各方面的成就，而他的《音樂大全》為後世木卡姆音樂的發展打下基礎。
          </p>
          <p>
            團隊飛快經歷「六套木卡姆」的發展，在了解其獲得聯合國非物質文化遺產的名銜後，眾人突然置身紐約皇后區街頭，這裡竟然也能聽到「六套木卡姆」的美妙音聲？來自中亞的布哈拉猶太人音樂家現身說法，分享他自小的學藝故事以及移民美國的心聲，在民族身份與音樂藝術之間的張力裡，他領悟到音樂的真諦……
          </p>
        </div>
      </div>
    </section>
    <div
      v-if="$route.params.id == 1"
      class="gallery-3-bg-layers-container"
    ></div>
    <div class="gallery-3-bg-container">
      <div
        :class="{
          'gallery-3-bg--blue': true,
          active: showArrows,
        }"
      ></div>
    </div>
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import btnHome from "@/components/btnHome";
import musicPlayer from "@/components/musicPlayer";
export default {
  data() {
    return {
      dimmed: true,
      sectionsName: [0, 1, 2, 3, 4],
    };
  },
  components: { btnHome, musicPlayer },
  mounted() {
    var vm = this;
    this.$store.commit("updateVisited", 2);
    if (this.$route.params.id != 1) {
      this.$store.commit("updateShowArrows", true);
    }

    document.querySelector("html,body").style.overflow = "auto";
    // this.playTrack();
    
  },
  methods: {
    clickSection(i) {
      if (i == this.currentSection) return;
      if (i != 0) {
        this.$store.commit("updateShowArrows", true);
      } else {
        this.$store.commit("updateShowArrows", false);
      }
      this.$store.commit("updateGallery3CurrentSection", i);
      this.$router.push({ name: "Scenes", params: { id: i + 1 } });
      // this.playTrack();
    },
  },
  computed: {
    currentSection() {
      return this.$store.state.gallery3.currentSection;
    },
    showArrows() {
      return this.$store.state.gallery3.showArrows;
    },
  },
};
</script>

<style lang="scss">
.gallery-3-bg-layers-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: url("../assets/images/gallery3/home/bg-layers.png") center center
    no-repeat;
  background-size: contain;
  transform: translateY(-5%);
}
.gallery-3-bg-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  background: rgb(35, 31, 32);
  background: linear-gradient(
    180deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(97, 89, 75, 1) 31%,
    rgba(193, 180, 154, 1) 53%,
    rgba(74, 71, 65, 1) 76%,
    rgba(35, 31, 32, 1) 100%
  );
}

.gallery-3-bg--blue {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  // background: rgb(35, 31, 32);
  // background: linear-gradient(
  //   180deg,
  //   rgba(35, 31, 32, 1) 0%,
  //   rgba(54, 64, 96, 1) 31%,
  //   rgba(140, 163, 230, 1) 53%,
  //   rgba(69, 76, 103, 1) 73%,
  //   rgba(35, 31, 32, 1) 100%
  // );
  opacity: 0;
  transition: all 1s ease-out;
  &.active {
    opacity: 1;
  }
}
</style>
