<template>
  <div class="music-player-container">
    <v-list flat dense color="transparent" class="music-player-container">
      <div class="track-title mt-2 d-none d-md-block">
        {{ sources[currentSource].name }}
      </div>
      <v-list-item>
        <div class="track-title d-flex d-md-none">
          {{ sources[currentSource].name }}
        </div>
        <v-btn @click="skipTo('prev')" icon>
          <v-icon dense small>mdi-skip-previous</v-icon>
        </v-btn>
        <v-btn v-if="isPlaying" @click="pauseSound" icon>
          <v-icon dense small>mdi-pause</v-icon>
        </v-btn>
        <v-btn v-else @click="playSound" icon>
          <v-icon dense small>mdi-play</v-icon>
        </v-btn>

        <v-btn @click="skipTo('next')" icon>
          <v-icon dense small>mdi-skip-next</v-icon>
        </v-btn>
        <!-- <v-btn @click="replay">
          <v-icon>mdi-restore</v-icon>
        </v-btn> -->
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import { gsap, Power2 } from "gsap";

export default {
  data() {
    return {
      tlTrackTitle: null,
      myTrack: null,
      currentSource: 0,
      nextSource: 1,
      sources: [
        { name: "untitled i", src: "1-untitled-i.mp3", player: null },
        { name: "mayfly fugue i", src: "2-mayfly-fugue-i.mp3", player: null },
        { name: "untitled ii", src: "3-untitled-ii.mp3", player: null },
        { name: "mayfly fugue ii", src: "4-mayfly-fugue-ii.mp3", player: null },
        { name: "untitled iii", src: "5-untitled-iii.mp3" },
        {
          name: "mayfly fugue iii",
          src: "6-mayfly-fugue-iii.mp3",
          player: null,
        },
      ],
    };
  },
  methods: {
    playSound() {
      this.sources[this.currentSource].player?.play();
      this.sources[this.currentSource].player?.once("play", () => {
        this.sources[this.currentSource].player?.fade(0, 1, 500);
      });
    },
    pauseSound() {
      if (this.isPlaying) {
        this.sources[this.currentSource].player?.once("fade", () => {
          this.sources[this.currentSource].player?.pause();
        });
        this.sources[this.currentSource].player?.fade(
          this.sources[this.currentSource].player.volume(),
          0,
          1000
        );
      }
    },
    skipTo(direction, current = null) {
      var vm = this;
      if (direction == "next") {
        if (this.currentSource < this.sources.length - 1) {
          this.nextSource = this.currentSource + 1;
        } else {
          this.nextSource = 0;
        }
      } else if (direction == "prev") {
        if (this.currentSource > 0) {
          this.nextSource = this.currentSource - 1;
        } else {
          this.nextSource = this.sources.length - 1;
        }
      }

      this.sources[this.currentSource].player?.once("fade", () => {
        this.sources[this.currentSource].player?.stop();
      });
      this.sources[this.currentSource].player?.fade(
        this.sources[this.currentSource].player.volume(),
        0,
        1000
      );
      this.tlTrackTitle = new gsap.timeline();
      this.tlTrackTitle.to(".track-title", {
        duration: 1,
        opacity: 0,
        ease: Power2.easeOut,
      });

      this.sources[this.currentSource].player?.once("stop", () => {
        this.sources[this.currentSource].player.unload();
        this.playNextSong();
      });
    },
    playNextSong() {
      var vm = this;
      this.sources[this.nextSource].player = new Howl({
        src: [`../../music/gallery3/${this.sources[this.nextSource].src}`],
        loop: false,
      });
      this.sources[this.nextSource]?.player.once("load", () => {
        this.tlTrackTitle.to(".track-title", {
          duration: 0.5,
          opacity: 1,
          ease: Power2.easeOut,
        });
        this.currentSource = this.nextSource;
        this.sources[this.currentSource].player?.fade(0, 1, 500);
        // this.sources[this.currentSource].player.seek(40);
        this.sources[this.currentSource].player.play();
      });
      this.sources[this.nextSource]?.player.once("end", () => {
        vm.skipTo("next");
      });
    },
  },
  destroyed() {
    if (this.isPlaying) {
      this.sources[this.currentSource].player?.once("fade", () => {
        this.sources[this.currentSource].player?.unload();
      });
      this.sources[this.currentSource].player?.fade(
        this.sources[this.currentSource].player.volume(),
        0,
        1000
      );
    }
  },
  mounted() {
    this.sources[this.currentSource].player = new Howl({
      src: [`../../music/gallery3/${this.sources[this.currentSource].src}`],
      loop: false,
    });

    this.sources[this.currentSource].player.once("load", () => {
      this.sources[this.currentSource].player.stop();
      this.sources[this.currentSource].player.play();
      // this.sources[this.currentSource].player.seek(270);
    });

    this.sources[this.currentSource].player.once("end", () => {
      this.skipTo("next");
    });
  },
  computed: {
    isPlaying() {
      return this.sources[this.currentSource].player?.playing();
    },
    isPlayingNext() {
      return this.sources[this.nextSource].player?.playing();
    },
  },
};
</script>

<style>
.track-title {
  /* flex: 1 1 100%; */
  text-align: center;
}
.music-player-container .v-list-item {
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 960px) {
}
</style>
