var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exhibition-container",attrs:{"id":"exhibition-3"}},[_c('btnHome'),_c('div',{staticClass:"right-toolbar"},[_vm._m(0),_c('div',{staticClass:"section-btn-container"},_vm._l((_vm.sectionsName),function(item,i){return _c('div',{key:("section-" + i),class:{ 'section-btn': true, active: _vm.currentSection == i },on:{"click":function($event){return _vm.clickSection(i)}}},[_c('div',{staticClass:"btn-color"})])}),0),_c('musicPlayer')],1),(_vm.$route.params.id == 1)?_c('section',{ref:"sectionWrapper",class:{
      'section-wrapper': true,
      'pointer-events-none': !_vm.dimmed,
      'fade-out': !_vm.dimmed,
    }},[_vm._m(1)]):_vm._e(),(_vm.$route.params.id == 1)?_c('div',{staticClass:"gallery-3-bg-layers-container"}):_vm._e(),_c('div',{staticClass:"gallery-3-bg-container"},[_c('div',{class:{
        'gallery-3-bg--blue': true,
        active: _vm.showArrows,
      }})]),_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subtitle"},[_c('img',{attrs:{"src":require("@/assets/images/subtitle-3.svg"),"width":"100%","height":"auto","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-section section-intro mb-15"},[_c('div',{staticClass:"section-title"},[_c('img',{staticClass:"title",attrs:{"src":require("@/assets/images/exhibition-3-title@2x.png"),"alt":"閱讀空間 bibliotheca","width":"100%","height":"auto"}})]),_c('div',{staticClass:"section-panel-text"},[_c('p',[_vm._v(" 創作團隊踏上探索中亞音樂之旅，邊走邊發現，邊發問邊思考。 ")]),_c('p',[_vm._v(" 團隊首先遇上屬於大草原的傳統遊牧音樂文化，體驗哈薩克人在三大人生階段都不可或缺的音樂元素，聆聽吉爾吉斯最有代表性的樂器——火不思——感化惡人的故事。 ")]),_c('p',[_vm._v(" 正當團隊在大草原上記錄著考察的各種發現、沉醉在遊牧文化與大自然的緊密連結時，突然時空大兜亂，瞬間轉移到中世紀中亞古城，與遊牧牧文明截然不同的文化氣息撲面而來……見證了當時著名的博學家法拉比各方面的成就，而他的《音樂大全》為後世木卡姆音樂的發展打下基礎。 ")]),_c('p',[_vm._v(" 團隊飛快經歷「六套木卡姆」的發展，在了解其獲得聯合國非物質文化遺產的名銜後，眾人突然置身紐約皇后區街頭，這裡竟然也能聽到「六套木卡姆」的美妙音聲？來自中亞的布哈拉猶太人音樂家現身說法，分享他自小的學藝故事以及移民美國的心聲，在民族身份與音樂藝術之間的張力裡，他領悟到音樂的真諦…… ")])])])}]

export { render, staticRenderFns }